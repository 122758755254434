"use client";
import React from "react";
import styles from "./ExpandableText.module.scss";
import { Flex } from "@radix-ui/themes";
import { TextProps, TextV2 } from "@/design-system/components/text/TextV2";
import { ButtonV2 } from "@/design-system/components/button/ButtonV2";
import { debounce } from "@/utils/FunctionUtils";
import { useTranslations } from "next-intl";
import classNames from "classnames";

interface ExpandableTextOwnProps {
  profileLayout?: boolean;
}

interface ExpandableTextProps extends TextProps, ExpandableTextOwnProps {}

const ExpandableText = (props: ExpandableTextProps) => {
  const {
    textStyle = "Body L",
    profileLayout = false,
    ...otherProps
  } = props;
  const [clamped, setClamped] = React.useState(true);
  const [showButton, setShowButton] = React.useState(false);
  const containerRef = React.useRef<HTMLSpanElement | null>(null);
  const t = useTranslations("Common");
  React.useEffect(() => {
    const hasClamping = (el: HTMLSpanElement) => {
      const { clientHeight, scrollHeight } = el;
      return clientHeight !== scrollHeight;
    };

    const checkButtonAvailability = () => {
      if (containerRef.current) {
        // Save current state to reapply later if necessary.
        const hadClampClass = containerRef.current.classList.contains("clamp");
        // Make sure that CSS clamping is applied if aplicable.
        if (!hadClampClass) containerRef.current.classList.add("clamp");
        // Check for clamping and show or hide button accordingly.
        setShowButton(hasClamping(containerRef.current));
        // Sync clamping with local state.
        if (!hadClampClass) containerRef.current.classList.remove("clamp");
      }
    };

    const debouncedCheck = debounce(checkButtonAvailability, 50);

    checkButtonAvailability();
    window.addEventListener("resize", debouncedCheck);

    return () => {
      window.removeEventListener("resize", debouncedCheck);
    };
  }, [containerRef]);

  return (
    <Flex className={classNames(styles.container, {[styles.profileLayout]: profileLayout})} direction={"column"} gap={"3"}>
      <TextV2
        ref={containerRef}
        textStyle={textStyle}
        className={classNames({
          [styles.clamp]: clamped,
        })}
        {...otherProps}
      />

      {showButton && !profileLayout && (
        <Flex
          className={styles.buttonContainer}
          direction={"column"}
          align={{ initial: "center", md: "start" }}
        >
          <ButtonV2
            className={styles.button}
            onClick={() => setClamped(!clamped)}
            variant="ghost"
            color={"gray"}
            size="4"
            mt={"4"}
          >
            {clamped ? "+ " + t("read_more") : "— " + t("read_less")}
          </ButtonV2>
        </Flex>
      )}

      {showButton && profileLayout && (
        <Flex
          className={styles.buttonContainer}
          direction={"column"}
          align={"start"}
        >
          <ButtonV2
            className={styles.button}
            onClick={() => setClamped(!clamped)}
            variant="ghost"
            color={"gray"}
            size="3"
            mt={"1"}
          >
            {clamped ? t("read_more") : t("read_less")}
          </ButtonV2>
        </Flex>
      )}
    </Flex>
  );
};

export default ExpandableText;
